import { Arrow, ArrowEditor } from "./gadgets/Arrow";
import { Bar, BarEditor } from "./gadgets/Bar";
import { Bento, BentoEditor } from "./gadgets/Bento";
import { Bridge, BridgeEditor } from "./gadgets/Bridge";
import { Calendar, CalendarEditor } from "./gadgets/Calendar";
import { Char, CharEditor } from "./gadgets/Char";
import { Clock, ClockEditor } from "./gadgets/Clock";
import { Cloud, CloudEditor } from "./gadgets/Cloud";
import { Cube, CubeEditor } from "./gadgets/Cube";
import { Icosahedron, IcosahedronEditor } from "./gadgets/Icosahedron";
import { Jack, JackEditor } from "./gadgets/Jack";
import { Magnifier, MagnifierEditor } from "./gadgets/Magnifier";
import { Pill, PillEditor } from "./gadgets/Pill";
import { Polymer, PolymerEditor } from "./gadgets/Polymer";
import { Ring, RingEditor } from "./gadgets/Ring";
import { Stool, StoolEditor } from "./gadgets/Stool";
import { Switch, SwitchEditor } from "./gadgets/Switch";
import { Tetromino, TetrominoEditor } from "./gadgets/Tetromino";
import { Thermometer, ThermometerEditor } from "./gadgets/Thermometer";
import { WaterGlass, WaterGlassEditor } from "./gadgets/WaterGlass";

export const gadgetFactory = (id: string, primaryColor: string) => {
  if (id === "cube") {
    return <Cube primaryColor={primaryColor} />;
  } else if (id === "clock") {
    return <Clock primaryColor={primaryColor} />;
  } else if (id === "water_glass") {
    return <WaterGlass primaryColor={primaryColor} />;
  } else if (id === "arrow") {
    return <Arrow primaryColor={primaryColor} />;
  } else if (id === "stool") {
    return <Stool primaryColor={primaryColor} />;
  } else if (id === "bento") {
    return <Bento primaryColor={primaryColor} />;
  } else if (id === "tetromino") {
    return <Tetromino primaryColor={primaryColor} />;
  } else if (id === "char") {
    return <Char primaryColor={primaryColor} />;
  } else if (id === "bridge") {
    return <Bridge primaryColor={primaryColor} />;
  } else if (id === "thermometer") {
    return <Thermometer primaryColor={primaryColor} />;
  } else if (id === "pill") {
    return <Pill primaryColor={primaryColor} />;
  } else if (id === "cloud") {
    return <Cloud primaryColor={primaryColor} />;
  } else if (id === "switch") {
    return <Switch primaryColor={primaryColor} />;
  } else if (id === "ring") {
    return <Ring primaryColor={primaryColor} />;
  } else if (id === "jack") {
    return <Jack primaryColor={primaryColor} />;
  } else if (id === "icosahedron") {
    return <Icosahedron primaryColor={primaryColor} />;
  } else if (id === "polymer") {
    return <Polymer primaryColor={primaryColor} />;
  } else if (id === "magnifier") {
    return <Magnifier primaryColor={primaryColor} />;
  } else if (id === "bar") {
    return <Bar primaryColor={primaryColor} />;
  } else if (id === "calendar") {
    return <Calendar primaryColor={primaryColor} />;
  }
  return <Cube primaryColor={primaryColor} />;
};

export const editorFactory = (id: string, primaryColor: string) => {
  if (id === "cube") {
    return <CubeEditor primaryColor={primaryColor} />;
  } else if (id === "clock") {
    return <ClockEditor primaryColor={primaryColor} />;
  } else if (id === "water_glass") {
    return <WaterGlassEditor primaryColor={primaryColor} />;
  } else if (id === "arrow") {
    return <ArrowEditor primaryColor={primaryColor} />;
  } else if (id === "stool") {
    return <StoolEditor primaryColor={primaryColor} />;
  } else if (id === "bento") {
    return <BentoEditor primaryColor={primaryColor} />;
  } else if (id === "tetromino") {
    return <TetrominoEditor primaryColor={primaryColor} />;
  } else if (id === "char") {
    return <CharEditor primaryColor={primaryColor} />;
  } else if (id === "bridge") {
    return <BridgeEditor primaryColor={primaryColor} />;
  } else if (id === "thermometer") {
    return <ThermometerEditor primaryColor={primaryColor} />;
  } else if (id === "pill") {
    return <PillEditor primaryColor={primaryColor} />;
  } else if (id === "cloud") {
    return <CloudEditor primaryColor={primaryColor} />;
  } else if (id === "switch") {
    return <SwitchEditor primaryColor={primaryColor} />;
  } else if (id === "ring") {
    return <RingEditor primaryColor={primaryColor} />;
  } else if (id === "jack") {
    return <JackEditor primaryColor={primaryColor} />;
  } else if (id === "icosahedron") {
    return <IcosahedronEditor primaryColor={primaryColor} />;
  } else if (id === "polymer") {
    return <PolymerEditor primaryColor={primaryColor} />;
  } else if (id === "magnifier") {
    return <MagnifierEditor primaryColor={primaryColor} />;
  } else if (id === "bar") {
    return <BarEditor primaryColor={primaryColor} />;
  } else if (id === "calendar") {
    return <CalendarEditor primaryColor={primaryColor} />;
  }
  return <CubeEditor primaryColor={primaryColor} />;
};
