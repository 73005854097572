import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import chroma from "chroma-js";

const FILENAME = "polymer";

interface IPolymerProps {
  primaryColor: string;
  radius?: number;
}

interface IPolymerEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const PolymerInternals = ({ primaryColor, radius }: IPolymerProps) => {
  if (radius === undefined) {
    radius = 1;
  }
  const sphereRadius = 0.35 * radius;
  const rodColor = "#ffffff";
  const colorL2 = chroma(primaryColor).set("hsl.h", "-144").hex();
  const colorL1 = chroma(primaryColor).set("hsl.h", "-72").hex();
  const colorR1 = chroma(primaryColor).set("hsl.h", "+72").hex();
  const colorR2 = chroma(primaryColor).set("hsl.h", "+144").hex();
  const metalness = 0.1;
  const roughness = 0.1;
  return (
    <>
      <mesh scale={[1, 1, 1]} position={[0.3, 0.3, 0]}>
        <mesh position={[0, 0, 0]}>
          <sphereGeometry args={[sphereRadius]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
        <mesh
          position={[1.5 / 4, (-Math.sqrt(3) * 1.5) / 4, 0]}
          rotation={[0, 0, Math.PI / 6]}
        >
          <cylinderGeometry args={[0.1, 0.1, 1.5]} />
          <meshStandardMaterial color={rodColor} />
        </mesh>
        <mesh
          position={[-2 / 4, (-Math.sqrt(3) * 2) / 4, 0]}
          rotation={[0, 0, -Math.PI / 6]}
        >
          <cylinderGeometry args={[0.1, 0.1, 2]} />
          <meshStandardMaterial color={rodColor} />
        </mesh>
        <mesh position={[-1, -Math.sqrt(3), 0]}>
          <sphereGeometry args={[sphereRadius]} />
          <meshStandardMaterial
            color={colorL1}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh
          position={[
            -2.5 / 4 - 1,
            -((Math.sqrt(3) * 2) / 2 - (Math.sqrt(3) * 2.5) / 4),
            0,
          ]}
          rotation={[0, 0, Math.PI / 6]}
        >
          <cylinderGeometry args={[0.1, 0.1, 2.5]} />
          <meshStandardMaterial color={rodColor} />
        </mesh>
        <mesh
          position={[-1 - 1.25, (Math.sqrt(3) * 2.5) / 2 - Math.sqrt(3), 0]}
        >
          <sphereGeometry args={[sphereRadius]} />
          <meshStandardMaterial
            color={colorL2}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh
          position={[(1.5 * 1.5) / 2, (-Math.sqrt(3) * 1.5) / 4, 0]}
          rotation={[0, 0, -Math.PI / 6]}
        >
          <cylinderGeometry args={[0.1, 0.1, 1.5]} />
          <meshStandardMaterial color={rodColor} />
        </mesh>
        <mesh position={[1.5, 0, 0]}>
          <sphereGeometry args={[sphereRadius]} />
          <meshStandardMaterial
            color={colorR1}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh position={[0.75, (-Math.sqrt(3) * 1.5) / 2, 0]}>
          <sphereGeometry args={[sphereRadius]} />
          <meshStandardMaterial
            color={colorR2}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <pointLight position={[6, 6, 6]} intensity={70} />
        <pointLight position={[-6, -6, -6]} intensity={70} />
      </mesh>
    </>
  );
};

export const Polymer = ({ primaryColor, radius }: IPolymerProps) => {
  return <PolymerInternals primaryColor={primaryColor} radius={radius} />;
};

const DownloadablePolymer = forwardRef(
  ({ primaryColor, radius }: IPolymerProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return <PolymerInternals primaryColor={primaryColor} radius={radius} />;
  }
);

export const PolymerEditor = ({ primaryColor }: IPolymerEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [radius, setRadius] = useState(1);

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Polymer primaryColor={primaryColor} radius={radius} />}
      downloadable={
        <DownloadablePolymer
          ref={childRef}
          primaryColor={primaryColor}
          radius={radius}
        />
      }
    >
      <Text size="sm">Atomic Weight</Text>
      <Slider
        min={1}
        max={5}
        step={0.01}
        value={radius}
        onChange={setRadius}
        className="track"
      />
    </ControlPanel>
  );
};
