import { Container, Stack, Text } from "@mantine/core";
import "./UnsupportedDisplay.css";
import Logo from "./Logo";

const UnsupportedDisplay = () => {
  return (
    <Container className="container">
      <Stack>
        <Logo />
        <Text>Toybox Icons is best experienced on wider screens.</Text>
      </Stack>
    </Container>
  );
};

export default UnsupportedDisplay;
