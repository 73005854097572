import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import { Base, Geometry, Subtraction } from "@react-three/csg";
import { temperature } from "chroma-js";
import "../CustomStyles.css";

const FILENAME = "thermometer";

interface IThermometerProps {
  primaryColor: string;
  temperature?: number;
}

interface IThermometerEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const ThermometerInternals = ({
  primaryColor,
  temperature,
}: IThermometerProps) => {
  if (temperature === undefined) {
    temperature = 75;
  }
  const mercuryHeight = (temperature / 100) * 3;
  return (
    <>
      <mesh scale={[1, 1, 1]} position={[0, -1.5, 0]}>
        <mesh position={[0, 3, 0]} rotation={[Math.PI / 2, 0, 0]}>
          <cylinderGeometry args={[0.25, 0.25, 0.2]} />
        </mesh>
        <mesh position={[0, 1.5, 0]}>
          <boxGeometry args={[0.5, 3, 0.2]} />
        </mesh>
        <mesh position={[0, mercuryHeight, 0.1]}>
          <sphereGeometry args={[0.1]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
        <mesh position={[0, mercuryHeight / 2, 0.1]}>
          <cylinderGeometry args={[0.1, 0.1, mercuryHeight]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
        <mesh rotation={[Math.PI / 2, 0, 0]}>
          <cylinderGeometry args={[0.5, 0.5, 0.2]} />
        </mesh>
        <mesh>
          <Geometry>
            <Base>
              <sphereGeometry args={[0.4]} />
            </Base>
            <Subtraction position={[0, 0, -0.4]}>
              <boxGeometry />
            </Subtraction>
          </Geometry>
          <meshStandardMaterial color={primaryColor} />
        </mesh>
      </mesh>
    </>
  );
};

export const Thermometer = ({
  primaryColor,
  temperature,
}: IThermometerProps) => {
  return (
    <ThermometerInternals
      primaryColor={primaryColor}
      temperature={temperature}
    />
  );
};

const DownloadableThermometer = forwardRef(
  ({ primaryColor, temperature }: IThermometerProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return (
      <ThermometerInternals
        primaryColor={primaryColor}
        temperature={temperature}
      />
    );
  }
);

export const ThermometerEditor = ({
  primaryColor,
}: IThermometerEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [temperature, setTemperature] = useState(25);

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={
        <Thermometer primaryColor={primaryColor} temperature={temperature} />
      }
      downloadable={
        <DownloadableThermometer
          ref={childRef}
          primaryColor={primaryColor}
          temperature={temperature}
        />
      }
    >
      <Text size="sm">Temperature (°C)</Text>
      <Slider
        className="track"
        min={0}
        max={100}
        step={0.1}
        value={temperature}
        onChange={setTemperature}
      />
    </ControlPanel>
  );
};
