import {
  Button,
  Card,
  Flex,
  Grid,
  Group,
  NativeSelect,
  Slider,
  Stack,
  Text,
} from "@mantine/core";
import "./tinkerer.css";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { IconDownload } from "@tabler/icons-react";
import { CameraControls, OrbitControls } from "@react-three/drei";
import { useEffect, useState } from "react";
import "./CustomStyles.css";
import { Euler, Matrix4, PerspectiveCamera, Quaternion, Vector3 } from "three";

interface IControlPanelProps {
  onClick: () => void;
  scene: React.ReactNode;
  downloadable: React.ReactNode;
  children?: React.ReactElement[];
}

const imageDimensions = ["128px", "265px", "512px", "1024px", "2048px"];

/**
 * Provides the camera object inside the interactable Canvas so that its
 * position and rotation data can be replicated in the Canvas used for downloading.
 */
const CameraProvider = ({
  setCamera,
}: {
  setCamera: (camera: PerspectiveCamera) => void;
}) => {
  const camera = useThree((s) => s.camera);
  useEffect(() => {
    setCamera(camera as PerspectiveCamera);
  }, [camera, setCamera]);

  return <></>;
};

/**
 * Controls the camera in the Canvas used for downloading.
 */
const CameraController = ({
  position,
  rotation,
}: {
  position: Vector3;
  rotation: Euler;
}) => {
  useFrame((state) => {
    state.camera.position.copy(position);
    state.camera.rotation.set(rotation.x, rotation.y, rotation.z);
  });
  return <></>;
};

export const decomposeMatrix = (matrix: Matrix4) => {
  const position: Vector3 = new Vector3();
  const rotation: Quaternion = new Quaternion();
  const scale: Vector3 = new Vector3();
  matrix.decompose(position, rotation, scale);
  return { position, rotation, scale };
};

const ControlPanel = ({
  onClick,
  scene,
  downloadable,
  children,
}: IControlPanelProps) => {
  const [dimensions, setDimensions] = useState("128px");
  const numericalDimensions = Number(dimensions.slice(0, -2));

  const defaultCameraPosition = new Vector3(0, 0, 6);
  const defaultCameraRotation = new Euler(0, 0, 0);

  const [camera, setCamera] = useState<PerspectiveCamera | null>(null);

  return (
    <Stack>
      <Card
        padding="lg"
        radius="md"
        withBorder
        style={{ aspectRatio: 1, borderColor: "var(--mantine-color-dark-1)" }}
        bg="beige"
        p={0}
      >
        <Canvas style={{ height: "50vh" }}>
          <CameraProvider setCamera={setCamera} />
          <ambientLight intensity={Math.PI / 2} />
          <pointLight position={[1, 1, 3]} intensity={7} />
          {scene}
          <OrbitControls enablePan={false} />
        </Canvas>
      </Card>

      <Canvas
        gl={{ preserveDrawingBuffer: true }}
        style={{
          visibility: "hidden",
          height: `${numericalDimensions}px`,
          width: `${numericalDimensions}px`,
          position: "fixed",
        }}
      >
        <ambientLight intensity={Math.PI / 2} />
        <pointLight position={[1, 1, 3]} intensity={7} />
        {downloadable}
        <CameraController
          position={camera ? camera?.position : defaultCameraPosition}
          rotation={camera ? camera?.rotation : defaultCameraRotation}
        />
      </Canvas>

      <div className="control-panel">
        {/* <Grid>
          <Grid.Col span={8}>
            <Text size="sm" mb="xs">
              Image Size
            </Text>
            <Slider
              min={16}
              max={128}
              step={16}
              value={dimensions}
              onChange={setDimensions}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Flex justify="flex-end">
              <Button fullWidth radius="lg" w="4em" onClick={onClick}>
                <IconDownload stroke={4} />
              </Button>
            </Flex>
          </Grid.Col>
        </Grid> */}
        <Group>
          <Button fullWidth radius="lg" w="4em" onClick={onClick}>
            <IconDownload stroke={4} />
          </Button>
          <NativeSelect
            styles={{
              input: {
                borderColor: "var(--mantine-color-dark-1)",
                backgroundColor: "var(--mantine-color-beige-0)",
                color: "var(--mantine-color-dark-1)",
              },
            }}
            data={imageDimensions}
            value={dimensions}
            onChange={(e) => setDimensions(e.target.value)}
          />
        </Group>
        {children}
      </div>
    </Stack>
  );
};

export default ControlPanel;
