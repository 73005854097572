import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ControlPanel from "../ControlPanel";
import { Base, Geometry, Subtraction } from "@react-three/csg";
import chroma from "chroma-js";

const FILENAME = "pill";

interface IPillProps {
  primaryColor: string;
}

interface IPillEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const PillInternals = ({ primaryColor }: IPillProps) => {
  const complimentaryColor = chroma(primaryColor).brighten(2).hex();
  return (
    <mesh>
      <mesh position={[-0.5, 0, 0]}>
        <Geometry>
          <Base rotation={[0, 0, Math.PI / 2]}>
            <capsuleGeometry args={[1, 1, 16, 32]} />
          </Base>
          <Subtraction position={[0.5, 0, 0]}>
            <boxGeometry />
          </Subtraction>
        </Geometry>
        <meshStandardMaterial color={primaryColor} />
      </mesh>
      <mesh position={[0.5, 0, 0]}>
        <Geometry>
          <Base rotation={[0, 0, Math.PI / 2]}>
            <capsuleGeometry args={[1, 1, 16, 32]} />
          </Base>
          <Subtraction position={[-0.5, 0, 0]}>
            <boxGeometry />
          </Subtraction>
        </Geometry>
        <meshStandardMaterial color={complimentaryColor} />
      </mesh>
    </mesh>
  );
};

export const Pill = ({ primaryColor }: IPillProps) => {
  return <PillInternals primaryColor={primaryColor} />;
};

const DownloadablePill = forwardRef(({ primaryColor }: IPillProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    download() {
      const link = document.createElement("a");
      link.setAttribute("download", `${FILENAME}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  }));

  return <PillInternals primaryColor={primaryColor} />;
});

export const PillEditor = ({ primaryColor }: IPillEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Pill primaryColor={primaryColor} />}
      downloadable={
        <DownloadablePill ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
