import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { Canvas, useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import TinkererWrapper from "../TinkererWrapper";

const FILENAME = "cube";

interface ICubeProps {
  primaryColor: string;
}

interface ICubeEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const CubeInternals = ({ primaryColor }: ICubeProps) => {
  return (
    <>
      <mesh scale={2} rotation={[Math.PI / 2, 0, 1]}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color={primaryColor} />
      </mesh>
    </>
  );
};

export const Cube = ({ primaryColor }: ICubeProps) => {
  return <CubeInternals primaryColor={primaryColor} />;
};

const DownloadableCube = forwardRef(({ primaryColor }: ICubeProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    download() {
      const link = document.createElement("a");
      link.setAttribute("download", `${FILENAME}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  }));

  return <CubeInternals primaryColor={primaryColor} />;
});

export const CubeEditor = ({ primaryColor }: ICubeEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Cube primaryColor={primaryColor} />}
      downloadable={
        <DownloadableCube ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
