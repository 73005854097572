import { Card, Text } from "@mantine/core";
import React from "react";

interface IGadgetCardProps {
  name: string;
  onClick: () => void;
  children: React.ReactElement;
}

const GadgetCard = ({ name, onClick, children }: IGadgetCardProps) => {
  return (
    <Card
      padding="lg"
      radius="md"
      withBorder
      onClick={onClick}
      style={{ aspectRatio: 1, borderColor: "var(--mantine-color-dark-1)" }}
      bg="beige"
      p={0}
    >
      {children}
      {/* <Card.Section>{children}</Card.Section> */}
      {/* <Text size="sm" style={{ textAlign: "center" }}>
        {name}
      </Text> */}
    </Card>
  );
};

export default GadgetCard;
