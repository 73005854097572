import { Card, Paper, Stack, Text, Title } from "@mantine/core";
import { useGalleryStore } from "./Store";
import { editorFactory } from "./GadgetFactory";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

const Tinkerer = () => {
  const { selectedGadget, primaryColor } = useGalleryStore((state) => ({
    selectedGadget: state.selectedGadget,
    primaryColor: state.primaryColor,
  }));

  return (
    <>
      {selectedGadget ? (
        <Stack p="xl">
          {selectedGadget && (
            <div style={{ height: "4em" }}>
              <Text size="xl">{selectedGadget.name}</Text>
              <Text size="md">{selectedGadget?.phoneticName}</Text>
            </div>
          )}
          {selectedGadget && editorFactory(selectedGadget.id, primaryColor)}
        </Stack>
      ) : (
        <Stack p="xl">
          <div style={{ height: "4em" }}></div>
          <Card
            padding="lg"
            radius="md"
            withBorder
            style={{
              aspectRatio: 1,
              borderColor: "var(--mantine-color-dark-1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "var(--mantine-color-beige-2)",
            }}
            bg="beige"
            p={0}
          >
            <Text size="md" my="xl" fw={500}>
              Select an icon from the gallery
            </Text>
          </Card>
        </Stack>
      )}
    </>
  );
};

export default Tinkerer;
