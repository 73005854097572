import {
  Button,
  Card,
  NativeSelect,
  RangeSlider,
  Slider,
  Stack,
  Text,
} from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import { Base, Geometry, Subtraction } from "@react-three/csg";
import chroma from "chroma-js";

const FILENAME = "bar";

interface IBarProps {
  primaryColor: string;
  lower?: number;
  upper?: number;
  material?: string;
}

interface IBarEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const materials = ["Metal", "Glass", "Matte"];

const BarInternals = ({ primaryColor, lower, upper, material }: IBarProps) => {
  const barLength = 3;
  if (lower === undefined) {
    lower = 10;
  }
  if (upper === undefined) {
    upper = 60;
  }
  if (material === undefined) {
    material = "Metal";
  }
  const width = (barLength * (upper - lower)) / 100;
  const position = width / 2 - barLength / 2 + (lower / 100) * barLength;

  const secondaryColor = chroma(primaryColor).brighten(1).hex();

  const getMaterial = () => {
    switch (material) {
      case "Metal":
        return (
          <meshStandardMaterial
            color={secondaryColor}
            metalness={0.1}
            roughness={0.1}
          />
        );
      case "Glass":
        return (
          <meshPhysicalMaterial
            color={secondaryColor}
            thickness={0.5}
            roughness={0}
            transmission={1}
            clearcoat={1}
            envMapIntensity={0.8}
            sheen={1}
          />
        );
      case "Matte":
        return <meshStandardMaterial color={secondaryColor} />;
      default:
        return <meshStandardMaterial color={secondaryColor} />;
    }
  };

  return (
    <mesh scale={[1, 1, 1]} position={[0, 0, 0]}>
      <Geometry>
        <Base>
          <boxGeometry args={[3, 1, 1]} />
        </Base>
        <Subtraction position={[position, 0, 0]}>
          <boxGeometry args={[width, 1, 1]} />
        </Subtraction>
      </Geometry>
      <meshStandardMaterial color={primaryColor} />
      <mesh position={[position, 0, 0]}>
        <boxGeometry args={[width, 1, 1]} />
        {getMaterial()}
      </mesh>
    </mesh>
  );
};

export const Bar = ({ primaryColor, lower, upper, material }: IBarProps) => {
  return (
    <BarInternals
      primaryColor={primaryColor}
      lower={lower}
      upper={upper}
      material={material}
    />
  );
};

const DownloadableBar = forwardRef(
  ({ primaryColor, lower, upper, material }: IBarProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return (
      <BarInternals
        primaryColor={primaryColor}
        lower={lower}
        upper={upper}
        material={material}
      />
    );
  }
);

export const BarEditor = ({ primaryColor }: IBarEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [lower, setLower] = useState(10);
  const [upper, setUpper] = useState(60);
  const [material, setMaterial] = useState(materials[0]);
  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={
        <Bar
          primaryColor={primaryColor}
          lower={lower}
          upper={upper}
          material={material}
        />
      }
      downloadable={
        <DownloadableBar
          ref={childRef}
          primaryColor={primaryColor}
          lower={lower}
          upper={upper}
          material={material}
        />
      }
    >
      <Text size="sm">Segmenter</Text>
      <RangeSlider
        min={0}
        max={100}
        minRange={5}
        step={5}
        value={[lower, upper]}
        onChange={(value) => {
          setLower(value[0]);
          setUpper(value[1]);
        }}
      />
      <Text size="sm">Material</Text>
      <NativeSelect
        styles={{
          input: {
            borderColor: "var(--mantine-color-dark-1)",
            backgroundColor: "var(--mantine-color-beige-0)",
            color: "var(--mantine-color-dark-1)",
          },
        }}
        data={materials}
        value={material}
        onChange={(e) => setMaterial(e.target.value)}
      />
    </ControlPanel>
  );
};
