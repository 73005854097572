import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ControlPanel from "../ControlPanel";

const FILENAME = "arrow";

interface IArrowProps {
  primaryColor: string;
}

interface IArrowEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const ArrowInternals = ({ primaryColor }: IArrowProps) => {
  return (
    <>
      <mesh scale={[2, 2, 2]}>
        <mesh position={[0, 0.6, 0]}>
          <coneGeometry args={[0.3, 0.6]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
        <mesh position={[0, 0, 0]}>
          <cylinderGeometry args={[0.1, 0.1, 1]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
      </mesh>
    </>
  );
};

export const Arrow = ({ primaryColor }: IArrowProps) => {
  return <ArrowInternals primaryColor={primaryColor} />;
};

const DownloadableArrow = forwardRef(({ primaryColor }: IArrowProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    download() {
      const link = document.createElement("a");
      link.setAttribute("download", `${FILENAME}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  }));

  return <ArrowInternals primaryColor={primaryColor} />;
});

export const ArrowEditor = ({ primaryColor }: IArrowEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Arrow primaryColor={primaryColor} />}
      downloadable={
        <DownloadableArrow ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
