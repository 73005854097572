import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { AppShell } from "@mantine/core";
import Gallery from "./Gallery";
import UnsupportedDisplay from "./UnsupportedDisplay";
import "@mantine/dates/styles.css";

const theme = createTheme({
  fontFamily: "Merriweather Sans, Open Sans, sans-serif",
  colors: {
    beige: [
      "#fff5eb",
      "#e3d8cf",
      "#8a7767",
      "#fff5eb",
      "#fff5eb",
      "#fff5eb",
      "#fff5eb",
      "#fff5eb",
      "#fff5eb",
      "#fff5eb",
    ],
    dark: [
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
    ],
  },
  primaryColor: "dark",
});

function App() {
  useEffect(() => {
    document.title = "Toybox";
  }, []);
  return (
    <MantineProvider theme={theme}>
      <UnsupportedDisplay />
      <AppShell padding="md" bg="beige">
        <AppShell.Main>
          <Gallery />
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
  );
}

export default App;
