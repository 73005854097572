import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import { Base, Geometry, Subtraction } from "@react-three/csg";
import chroma from "chroma-js";
import "../CustomStyles.css";

const FILENAME = "magnifier";

interface IMagnifierProps {
  primaryColor: string;
  tintStrength?: number;
}

interface IMagnifierEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const MagnifierInternals = ({
  primaryColor,
  tintStrength,
}: IMagnifierProps) => {
  if (tintStrength === undefined) {
    tintStrength = 0.5;
  }

  const metalness = 0.1;
  const roughness = 0.8;
  const glassColor = chroma(primaryColor)
    .brighten(3 - tintStrength)
    .hex();
  return (
    <>
      <mesh
        scale={[1, 1, 1]}
        rotation={[Math.PI / 2, Math.PI / 4, 0]}
        position={[-0.1, 0.3, 0]}
      >
        <mesh>
          <Geometry>
            <Base>
              <cylinderGeometry args={[1, 1, 0.25]} />
            </Base>
            <Subtraction>
              <cylinderGeometry args={[0.8, 0.8, 0.25]} />
            </Subtraction>
          </Geometry>
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh>
          <cylinderGeometry args={[0.8, 0.8, 0.1]} />
          <meshPhysicalMaterial
            color={glassColor}
            thickness={0.5}
            roughness={0}
            transmission={1}
            clearcoat={1}
            envMapIntensity={0.8}
            sheen={1}
            sheenRoughness={0.7}
          />
        </mesh>
        <mesh rotation={[Math.PI / 2, 0, 0]} position={[0, 0, 1.5]}>
          <capsuleGeometry args={[0.125, 1, 16, 32]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
      </mesh>
    </>
  );
};

export const Magnifier = ({ primaryColor, tintStrength }: IMagnifierProps) => {
  return (
    <MagnifierInternals
      primaryColor={primaryColor}
      tintStrength={tintStrength}
    />
  );
};

const DownloadableMagnifier = forwardRef(
  ({ primaryColor, tintStrength }: IMagnifierProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return (
      <MagnifierInternals
        primaryColor={primaryColor}
        tintStrength={tintStrength}
      />
    );
  }
);

export const MagnifierEditor = ({ primaryColor }: IMagnifierEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [tintStrength, setTintStrength] = useState(0.5);
  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={
        <Magnifier primaryColor={primaryColor} tintStrength={tintStrength} />
      }
      downloadable={
        <DownloadableMagnifier
          ref={childRef}
          primaryColor={primaryColor}
          tintStrength={tintStrength}
        />
      }
    >
      <Text size="sm">Tint Strength</Text>
      <Slider
        min={0}
        max={3}
        step={0.01}
        value={tintStrength}
        onChange={setTintStrength}
        className="track"
      />
    </ControlPanel>
  );
};
