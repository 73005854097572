import {
  Button,
  Card,
  Slider,
  Stack,
  Text,
  Switch as MantineSwitch,
} from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import { Addition, Base, Geometry, Subtraction } from "@react-three/csg";
import { temperature } from "chroma-js";

const FILENAME = "switch";

interface ISwitchProps {
  primaryColor: string;
  isOn?: boolean;
}

interface ISwitchEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const SwitchInternals = ({ primaryColor, isOn }: ISwitchProps) => {
  if (isOn === undefined) {
    isOn = true;
  }
  const metalness = 0.1;
  const roughness = 0.1;
  const bodyColor = "#ffffff";
  return (
    <>
      <pointLight position={[0, 0, 6]} intensity={40} />
      <mesh scale={[1.5, 1.5, 1.5]} position={[0, 0, 0]}>
        <mesh>
          <Geometry useGroups>
            <Base>
              <Geometry>
                <Base>
                  <boxGeometry args={[1, 0.9, 0.2]} />
                </Base>
                <Addition>
                  <Geometry>
                    <Base
                      rotation={[Math.PI / 2, 0, 0]}
                      position={[-0.5, 0, 0]}
                    >
                      <cylinderGeometry args={[0.45, 0.45, 0.2, 128]} />
                    </Base>
                    <Subtraction>
                      <boxGeometry args={[1, 0.9, 0.25]} />
                    </Subtraction>
                  </Geometry>
                </Addition>
                <Addition>
                  <Geometry>
                    <Base rotation={[Math.PI / 2, 0, 0]} position={[0.5, 0, 0]}>
                      <cylinderGeometry args={[0.45, 0.45, 0.2, 128]} />
                    </Base>
                    <Subtraction>
                      <boxGeometry args={[1, 0.9, 0.25]} />
                    </Subtraction>
                  </Geometry>
                </Addition>
              </Geometry>
              <meshStandardMaterial
                color={bodyColor}
                metalness={metalness}
                roughness={roughness}
              />
            </Base>
            <Subtraction position={[0, 0, 0.11]}>
              <boxGeometry args={[0.9, 0.8, 0.1]} />
              <meshStandardMaterial color={isOn ? primaryColor : "#999999"} />
            </Subtraction>
            <Subtraction
              rotation={[Math.PI / 2, 0, 0]}
              position={[-0.5, 0, 0.11]}
            >
              <cylinderGeometry args={[0.4, 0.4, 0.1, 128]} />
              <meshStandardMaterial color={isOn ? primaryColor : "#999999"} />
            </Subtraction>

            <Subtraction
              rotation={[Math.PI / 2, 0, 0]}
              position={[0.5, 0, 0.11]}
            >
              <cylinderGeometry args={[0.4, 0.4, 0.1, 128]} />
              <meshStandardMaterial color={isOn ? primaryColor : "#999999"} />
            </Subtraction>
          </Geometry>
        </mesh>
        <mesh
          rotation={[Math.PI / 2, 0, 0]}
          position={[isOn ? 0.45 : -0.45, 0, 0.1]}
        >
          <cylinderGeometry args={[0.35, 0.35, 0.05]} />
          <meshStandardMaterial
            color={bodyColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <pointLight position={[0, 0, -6]} intensity={70} />
      </mesh>
    </>
  );
};

export const Switch = ({ primaryColor, isOn }: ISwitchProps) => {
  return <SwitchInternals primaryColor={primaryColor} isOn={isOn} />;
};

const DownloadableSwitch = forwardRef(
  ({ primaryColor, isOn }: ISwitchProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return <SwitchInternals primaryColor={primaryColor} isOn={isOn} />;
  }
);

export const SwitchEditor = ({ primaryColor }: ISwitchEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [isOn, setIsOn] = useState(true);

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Switch primaryColor={primaryColor} isOn={isOn} />}
      downloadable={
        <DownloadableSwitch
          ref={childRef}
          primaryColor={primaryColor}
          isOn={isOn}
        />
      }
    >
      <Text size="sm">Is it on?</Text>
      <MantineSwitch
        checked={isOn}
        onChange={(e) => setIsOn(e.target.checked)}
        styles={{
          track: {
            backgroundColor: isOn ? primaryColor : "#999999",
            border: "none",
          },
        }}
      />
    </ControlPanel>
  );
};
