import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree, extend, Object3DNode } from "@react-three/fiber";
import { DateInput, Calendar as MantineCalendar } from "@mantine/dates";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ControlPanel from "../ControlPanel";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { Font, FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { RoundedBox, Center, Text as DreiText } from "@react-three/drei";

const FILENAME = "calendar";

extend({
  TextGeometry,
});

declare module "@react-three/fiber" {
  interface ThreeElements {
    textGeometry: Object3DNode<TextGeometry, typeof TextGeometry>;
  }
}

interface ICalendarProps {
  primaryColor: string;
  date?: Date;
}

interface ICalendarEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const CalendarInternals = ({ primaryColor, date }: ICalendarProps) => {
  if (date === undefined) {
    date = new Date();
  }
  const [font, setFont] = useState<Font | null>(null);
  useEffect(() => {
    const loader = new FontLoader();
    loader.load("fonts/Poppins.json", (data) => {
      setFont(data);
    });
  }, [setFont]);

  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString();
  console.log(month, day);
  return (
    <>
      {font && (
        <mesh scale={[2, 2, 2]}>
          <mesh>
            <RoundedBox args={[1, 1, 0.2]}>
              <meshStandardMaterial color={"#ffffff"} />
            </RoundedBox>
          </mesh>
          <mesh position={[0, 0.5, 0]}>
            <RoundedBox args={[1.05, 0.25, 0.25]}>
              <meshStandardMaterial color={primaryColor} />
            </RoundedBox>
          </mesh>
          <mesh position={[0, 0.6, 0]} rotation={[0, Math.PI / 2, 0]}>
            <torusGeometry args={[0.14, 0.04]} />
            <meshStandardMaterial color={"#000000"} />
          </mesh>
          <mesh position={[-0.25, 0.6, 0]} rotation={[0, Math.PI / 2, 0]}>
            <torusGeometry args={[0.14, 0.04]} />
            <meshStandardMaterial color={"#000000"} />
          </mesh>
          <mesh position={[0.25, 0.6, 0]} rotation={[0, Math.PI / 2, 0]}>
            <torusGeometry args={[0.14, 0.04]} />
            <meshStandardMaterial color={"#000000"} />
          </mesh>
          <pointLight position={[0, 0, 3]} intensity={50} />s
          <mesh position={[0, 0.2, 0.15]}>
            <DreiText
              font="/fonts/Poppins.ttf"
              fontSize={0.2}
              textAlign="center"
            >
              {month}
              <meshStandardMaterial color={"#000000"} />
            </DreiText>
          </mesh>
          <mesh position={[0, -0.15, 0.15]}>
            <DreiText
              font="/fonts/Poppins.ttf"
              fontSize={0.5}
              textAlign="center"
            >
              {day}
              <meshStandardMaterial color={"#000000"} />
            </DreiText>
          </mesh>
        </mesh>
      )}
    </>
  );
};

export const Calendar = ({ primaryColor, date }: ICalendarProps) => {
  return <CalendarInternals primaryColor={primaryColor} date={date} />;
};

const DownloadableCalendar = forwardRef(
  ({ primaryColor, date }: ICalendarProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return <CalendarInternals primaryColor={primaryColor} date={date} />;
  }
);

export const CalendarEditor = ({ primaryColor }: ICalendarEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [date, setDate] = useState<Date>(new Date());
  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Calendar primaryColor={primaryColor} date={date} />}
      downloadable={
        <DownloadableCalendar
          ref={childRef}
          primaryColor={primaryColor}
          date={date}
        />
      }
    >
      <Text size="sm">Date</Text>
      <DateInput
        value={date}
        placeholder="Date input"
        onChange={(date) => setDate(date || new Date())}
        styles={{
          input: {
            borderColor: "var(--mantine-color-dark-1)",
            backgroundColor: "var(--mantine-color-beige-0)",
            color: "var(--mantine-color-dark-1)",
          },
        }}
      />
    </ControlPanel>
  );
};
