import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ControlPanel from "../ControlPanel";

const FILENAME = "jack";

interface IJackProps {
  primaryColor: string;
}

interface IJackEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const JackInternals = ({ primaryColor }: IJackProps) => {
  const metalness = 0.6;
  const roughness = 0.45;
  return (
    <>
      <mesh scale={[1.5, 1.5, 1.5]} rotation={[Math.PI / 1.5, 0, Math.PI / 4]}>
        <mesh position={[0, 0, 0]}>
          <capsuleGeometry args={[0.15, 2]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh position={[0, 0, 0]} rotation={[0, 0, Math.PI / 2]}>
          <capsuleGeometry args={[0.15, 2]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]}>
          <capsuleGeometry args={[0.15, 2]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh position={[-1, 0, 0]}>
          <sphereGeometry args={[0.3]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh position={[1, 0, 0]}>
          <sphereGeometry args={[0.3]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh position={[0, 1, 0]}>
          <sphereGeometry args={[0.3]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <mesh position={[0, -1, 0]}>
          <sphereGeometry args={[0.3]} />
          <meshStandardMaterial
            color={primaryColor}
            metalness={metalness}
            roughness={roughness}
          />
        </mesh>
        <pointLight position={[6, 6, 6]} intensity={70} />
      </mesh>
    </>
  );
};

export const Jack = ({ primaryColor }: IJackProps) => {
  return <JackInternals primaryColor={primaryColor} />;
};

const DownloadableJack = forwardRef(({ primaryColor }: IJackProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    download() {
      const link = document.createElement("a");
      link.setAttribute("download", `${FILENAME}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  }));

  return <JackInternals primaryColor={primaryColor} />;
});

export const JackEditor = ({ primaryColor }: IJackEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Jack primaryColor={primaryColor} />}
      downloadable={
        <DownloadableJack ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
