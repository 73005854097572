import { Group, Kbd, Stack, Text } from "@mantine/core";
import { useThree, extend, Object3DNode } from "@react-three/fiber";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ControlPanel from "../ControlPanel";
import { Font, FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { Center } from "@react-three/drei";

const FILENAME = "char";

extend({
  TextGeometry,
});

declare module "@react-three/fiber" {
  interface ThreeElements {
    textGeometry: Object3DNode<TextGeometry, typeof TextGeometry>;
  }
}

interface ICharProps {
  primaryColor: string;
  char?: string;
}

interface ICharEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const CharInternals = ({ primaryColor, char }: ICharProps) => {
  if (char === undefined) {
    char = "T";
  }

  const [font, setFont] = useState<Font | null>(null);
  useEffect(() => {
    const loader = new FontLoader();
    loader.load("fonts/Poppins.json", (data) => {
      setFont(data);
    });
  }, [setFont]);

  return (
    <>
      {font && (
        <Center>
          <mesh scale={2}>
            <textGeometry args={[char, { font, size: 1, height: 0.3 }]} />
            <meshStandardMaterial color={primaryColor} />
          </mesh>
        </Center>
      )}
    </>
  );
};

export const Char = ({ primaryColor, char }: ICharProps) => {
  return <CharInternals primaryColor={primaryColor} char={char} />;
};

const DownloadableChar = forwardRef(
  ({ primaryColor, char }: ICharProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return <CharInternals primaryColor={primaryColor} char={char} />;
  }
);

export const CharEditor = ({ primaryColor }: ICharEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [rotation, setRotation] = useState(0);
  const [char, setChar] = useState("T");

  const createRow = (chars: string[]) => {
    return chars.map((char) => (
      <Kbd
        key={char}
        onClick={() => setChar(char)}
        style={{
          cursor: "pointer",
        }}
      >
        {char}
      </Kbd>
    ));
  };

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Char primaryColor={primaryColor} char={char} />}
      downloadable={
        <DownloadableChar
          ref={childRef}
          primaryColor={primaryColor}
          char={char}
        />
      }
    >
      <Text size="sm">Key</Text>
      <Stack gap="sm">
        <Group gap="xs">
          {createRow(["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"])}
        </Group>
        <Group gap="xs" ml="md">
          {createRow(["A", "S", "D", "F", "G", "H", "J", "K", "L"])}
        </Group>
        <Group gap="xs" ml="xl">
          {createRow(["Z", "X", "C", "V", "B", "N", "M"])}
        </Group>
      </Stack>
    </ControlPanel>
  );
};
