import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ControlPanel from "../ControlPanel";

const FILENAME = "icosahedron";

interface IIcosahedronProps {
  primaryColor: string;
}

interface IIcosahedronEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const IcosahedronInternals = ({ primaryColor }: IIcosahedronProps) => {
  return (
    <>
      <mesh scale={[1.5, 1.5, 1.5]}>
        <icosahedronGeometry />
        <meshStandardMaterial color={primaryColor} />
      </mesh>
    </>
  );
};

export const Icosahedron = ({ primaryColor }: IIcosahedronProps) => {
  return <IcosahedronInternals primaryColor={primaryColor} />;
};

const DownloadableIcosahedron = forwardRef(
  ({ primaryColor }: IIcosahedronProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return <IcosahedronInternals primaryColor={primaryColor} />;
  }
);

export const IcosahedronEditor = ({
  primaryColor,
}: IIcosahedronEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Icosahedron primaryColor={primaryColor} />}
      downloadable={
        <DownloadableIcosahedron ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
