import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import { RoundedBox } from "@react-three/drei";
import "../CustomStyles.css";

const FILENAME = "bento";

interface IBentoProps {
  primaryColor: string;
  size?: number;
}

interface IBentoEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const BentoInternals = ({ primaryColor, size }: IBentoProps) => {
  if (size === undefined) {
    size = 2;
  }

  let list = [];
  const cellWidth = 1;
  const padding = 0.2;
  for (let i = 0; i < size; i++) {
    for (let j = 0; j < size; j++) {
      list.push(
        <mesh position={[j + j * padding, i + i * padding, 0]}>
          <RoundedBox
            key={`${i}-${j}`}
            args={[cellWidth, cellWidth, 0.2]}
            radius={0.1}
          >
            <meshBasicMaterial color={primaryColor} />
          </RoundedBox>
        </mesh>
      );
    }
  }

  const totalWidth = (cellWidth + padding) * size + padding;
  return (
    <>
      <mesh
        scale={[1, 1, 1]}
        position={[
          -totalWidth / 2 + (cellWidth / 2 + padding),
          -totalWidth / 2 + (cellWidth / 2 + padding),
          0,
        ]}
      >
        {list}
      </mesh>
    </>
  );
};

export const Bento = ({ primaryColor, size }: IBentoProps) => {
  return <BentoInternals primaryColor={primaryColor} size={size} />;
};

const DownloadableBento = forwardRef(
  ({ primaryColor, size }: IBentoProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return <BentoInternals primaryColor={primaryColor} size={size} />;
  }
);

export const BentoEditor = ({ primaryColor }: IBentoEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [size, setSize] = useState(2);
  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Bento primaryColor={primaryColor} size={size} />}
      downloadable={
        <DownloadableBento
          ref={childRef}
          primaryColor={primaryColor}
          size={size}
        />
      }
    >
      <Text size="sm">Size</Text>
      <Slider
        className="track"
        min={1}
        max={4}
        step={1}
        value={size}
        onChange={setSize}
      />
    </ControlPanel>
  );
};
