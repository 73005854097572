import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ControlPanel from "../ControlPanel";

const FILENAME = "cloud";

interface ICloudProps {
  primaryColor: string;
}

interface ICloudEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const CloudInternals = ({ primaryColor }: ICloudProps) => {
  return (
    <>
      <mesh scale={[2.5, 2.5, 2.5]}>
        <mesh position={[-0.35, -0.15, 0]}>
          <sphereGeometry args={[0.25]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
        <mesh position={[0, 0, 0]}>
          <sphereGeometry args={[0.4]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
        <mesh position={[0.35, -0.15, 0]}>
          <sphereGeometry args={[0.25]} />
          <meshStandardMaterial color={primaryColor} />
        </mesh>
        <pointLight position={[0, 1, 3]} intensity={300} />
      </mesh>
    </>
  );
};

export const Cloud = ({ primaryColor }: ICloudProps) => {
  return <CloudInternals primaryColor={primaryColor} />;
};

const DownloadableCloud = forwardRef(({ primaryColor }: ICloudProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    download() {
      const link = document.createElement("a");
      link.setAttribute("download", `${FILENAME}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  }));

  return <CloudInternals primaryColor={primaryColor} />;
});

export const CloudEditor = ({ primaryColor }: ICloudEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Cloud primaryColor={primaryColor} />}
      downloadable={
        <DownloadableCloud ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
