import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ControlPanel from "../ControlPanel";
import { Base, Geometry, Subtraction } from "@react-three/csg";
import chroma from "chroma-js";

const FILENAME = "bridge";

interface IBridgeProps {
  primaryColor: string;
}

interface IBridgeEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const BridgeInternals = ({ primaryColor }: IBridgeProps) => {
  const complimentaryColor = chroma(primaryColor).brighten(2).hex();
  return (
    <mesh>
      <mesh position={[-0.5, 0, 0]}>
        <Geometry>
          <Base>
            <boxGeometry />
          </Base>
          <Subtraction position={[0.5, -0.5, 0]} rotation={[Math.PI / 2, 0, 0]}>
            <cylinderGeometry args={[0.5, 0.5, 1]} />
          </Subtraction>
        </Geometry>
        <meshStandardMaterial color={primaryColor} />
      </mesh>
      <mesh position={[0.5, 0, 0]}>
        <Geometry>
          <Base>
            <boxGeometry />
          </Base>
          <Subtraction
            position={[-0.5, -0.5, 0]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <cylinderGeometry args={[0.5, 0.5, 1]} />
          </Subtraction>
        </Geometry>
        <meshStandardMaterial color={complimentaryColor} />
      </mesh>
    </mesh>
  );
};

export const Bridge = ({ primaryColor }: IBridgeProps) => {
  return <BridgeInternals primaryColor={primaryColor} />;
};

const DownloadableBridge = forwardRef(({ primaryColor }: IBridgeProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    download() {
      const link = document.createElement("a");
      link.setAttribute("download", `${FILENAME}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  }));

  return <BridgeInternals primaryColor={primaryColor} />;
});

export const BridgeEditor = ({ primaryColor }: IBridgeEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Bridge primaryColor={primaryColor} />}
      downloadable={
        <DownloadableBridge ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
