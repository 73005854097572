import { Button, Slider, Text } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { Canvas, useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Euler, Matrix4, Quaternion, Vector2, Vector3 } from "three";
import { rotateAboutAnchor } from "../utils";
import "../tinkerer.css";
import { Cylinder } from "../shapes";
import ControlPanel from "../ControlPanel";

const FILENAME = "clock";

interface IClockProps {
  primaryColor: string;
  hour?: number;
  minute?: number;
}

interface IClockEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const ClockInternals = ({ primaryColor, hour, minute }: IClockProps) => {
  if (!hour) {
    hour = -Math.PI / 2;
  }
  if (!minute) {
    minute = 0;
  }

  const { newPos: hourArmPos, newRot: hourArmRot } = rotateAboutAnchor(
    new Vector3(0, 0.3, 0.3),
    new Vector3(0, 0, 0),
    new Vector3(0, 0, 0),
    hour
  );

  const { newPos: minuteArmPos, newRot: minuteArmRot } = rotateAboutAnchor(
    new Vector3(0, 0.3, 0.3),
    new Vector3(0, 0, 0),
    new Vector3(0, 0, 0),
    minute
  );
  return (
    <>
      <group rotation={[0, 0, 0]}>
        <Cylinder
          rotation={[Math.PI / 2, 0, 0]}
          radiusTop={1}
          radiusBottom={1}
          height={0.6}
          color={"#FFFFFF"}
        />

        <Cylinder
          rotation={[Math.PI / 2, 0, 0]}
          radiusTop={1.001}
          radiusBottom={1.001}
          height={0.6}
          isOpenEnded
          color={primaryColor}
        />

        <group scale={1} position={[0, 0, -0.301]} rotation={[0, Math.PI, 0]}>
          <mesh>
            <circleGeometry args={[1]} />
            <meshStandardMaterial color={primaryColor} />
          </mesh>
        </group>

        <group scale={1} position={[0, 0, 0.3]} rotation={[0, 0, 0]}>
          <mesh>
            <torusGeometry args={[0.9, 0.1]} />
            <meshStandardMaterial color={primaryColor} />
          </mesh>
        </group>

        <group scale={1} position={[0, 0, -0.3]} rotation={[0, 0, 0]}>
          <mesh>
            <torusGeometry args={[0.9, 0.1]} />
            <meshStandardMaterial color={primaryColor} />
          </mesh>
        </group>

        <group scale={1} position={[0, 0, 0.325]} rotation={[0, 0, 0]}>
          <mesh>
            <sphereGeometry args={[0.1]} />
            <meshStandardMaterial color={"#000000"} />
          </mesh>
        </group>

        <group scale={1} position={minuteArmPos} rotation={minuteArmRot}>
          <mesh>
            <capsuleGeometry args={[0.06, 0.6]} />
            <meshStandardMaterial color={"#000000"} />
          </mesh>
        </group>
        <group scale={1} position={hourArmPos} rotation={hourArmRot}>
          <mesh>
            <capsuleGeometry args={[0.06, 0.4]} />
            <meshStandardMaterial color={"#000000"} />
          </mesh>
        </group>
      </group>
    </>
  );
};

export const Clock = ({ primaryColor, hour, minute }: IClockProps) => {
  return (
    <ClockInternals primaryColor={primaryColor} hour={hour} minute={minute} />
  );
};

const DownloadableClock = forwardRef(
  ({ primaryColor, hour, minute }: IClockProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return (
      <ClockInternals primaryColor={primaryColor} hour={hour} minute={minute} />
    );
  }
);

const militaryTimeToRotations = (time: string) => {
  const [hour, minute]: string[] = time.split(":");

  const numericHour = Number(hour) % 12;
  const numericMinute = Number(minute);

  const hourRotation = -(numericHour / 12) * 2 * Math.PI;
  const minuteRotation = -(numericMinute / 60) * 2 * Math.PI;
  return { hourRotation, minuteRotation };
};

export const ClockEditor = ({ primaryColor }: IClockEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [time, setTime] = useState("15:00");

  const { hourRotation, minuteRotation } = militaryTimeToRotations(time);

  return (
    <ControlPanel
      scene={
        <Clock
          primaryColor={primaryColor}
          hour={hourRotation}
          minute={minuteRotation}
        />
      }
      downloadable={
        <DownloadableClock
          ref={childRef}
          primaryColor={primaryColor}
          hour={hourRotation}
          minute={minuteRotation}
        />
      }
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
    >
      <Text size="sm">Time of Day</Text>
      <TimeInput
        styles={{
          input: {
            borderColor: "var(--mantine-color-dark-1)",
            backgroundColor: "var(--mantine-color-beige-0)",
            color: "var(--mantine-color-dark-1)",
          },
        }}
        value={time}
        onChange={(e) => setTime(e.target.value)}
      />
    </ControlPanel>
  );
};
