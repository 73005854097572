import {
  ColorInput,
  Divider,
  Grid,
  Group,
  ScrollArea,
  SimpleGrid,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import GadgetCard from "./GadgetCard";
import { MutableRefObject, createRef, useState } from "react";
import { useGalleryStore } from "./Store";
import { gadgetFactory } from "./GadgetFactory";
import Tinkerer from "./Tinkerer";
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera, View } from "@react-three/drei";
import Logo from "./Logo";
import "./CustomStyles.css";

function Gallery() {
  const [library, setLibrary] = useState<any[]>([
    {
      id: "bento",
      name: "Bento",
      phoneticName: "/ˈbenˌtō/",
      searchTerms: ["box", "menu"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "tetromino",
      name: "Tetromino",
      phoneticName: "/tetromino/",
      searchTerms: ["tetris", "game", "blocks"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "char",
      name: "Character",
      phoneticName: "/ˈkærəktə/",
      searchTerms: ["text, letter"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "bridge",
      name: "Bridge",
      phoneticName: "/brij/",
      searchTerms: ["toy", "blocks"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "jack",
      name: "Jack",
      phoneticName: "/ʤæk/",
      searchTerms: ["toy"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "pill",
      name: "Pill",
      phoneticName: "/pil/",
      searchTerms: ["medicine", "capsule"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "polymer",
      name: "Polymer",
      phoneticName: "/ˈpɒlɪmə/",
      searchTerms: ["shape", "chain", "chemical"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "ring",
      name: "Ring",
      phoneticName: "/rɪŋ/",
      searchTerms: ["torus", "donut", "circle", "round"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "cube",
      name: "Cube",
      phoneticName: "/kyo͞ob/",
      searchTerms: ["blocks", "primitive", "square", "shape"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "icosahedron",
      name: "Icosahedron",
      phoneticName: "/aɪkɒsəˈhiːdrən/",
      searchTerms: ["shape", "primitive"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "bar",
      name: "Candy Bar",
      phoneticName: "/ˈkændi bɑː/",
      searchTerms: ["bar", "block"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "clock",
      name: "Clock",
      phoneticName: "/kläk/",
      searchTerms: ["timer", "analog"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "water_glass",
      name: "Glass of Water",
      phoneticName: "/ɡlas/",
      searchTerms: ["cup", "juice", "container"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "arrow",
      name: "Arrow",
      phoneticName: "/ˈerō/",
      searchTerms: ["pointer"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "calendar",
      name: "Calendar",
      phoneticName: "/ˈkæləndə/",
      searchTerms: ["today", "date"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "stool",
      name: "Stool",
      phoneticName: "/sto͞ol/",
      searchTerms: ["chair", "sit", "table"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "thermometer",
      name: "Thermometer",
      phoneticName: "/THərˈmämədər/",
      searchTerms: ["temperature", "measurement"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "magnifier",
      name: "Magnifier",
      phoneticName: "/ˈmæɡnɪfaɪə/",
      searchTerms: ["glass", "magnifying", "zoom"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "switch",
      name: "Switch",
      phoneticName: "/swiCH/",
      searchTerms: ["settings", "toggle"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
    {
      id: "cloud",
      name: "Cloud",
      phoneticName: "/kloud/",
      searchTerms: ["weather"],
      ref: createRef() as MutableRefObject<HTMLDivElement>,
    },
  ]);

  const { primaryColor, selectedGadget, setPrimaryColor, setSelectedGadget } =
    useGalleryStore((state) => ({
      primaryColor: state.primaryColor,
      selectedGadget: state.selectedGadget,
      setPrimaryColor: state.setPrimaryColor,
      setSelectedGadget: state.setSelectedGadget,
    }));

  const jsxObjects = () => {
    return library.map((item, index) => (
      <group key={item.id} position={[index * 10, 0, 0]}>
        {gadgetFactory(item.id, primaryColor)}
      </group>
    ));
  };

  const [query, setQuery] = useState("");

  const filteredIcons = library.filter((icon) => {
    const normalizedQuery = query.toLowerCase();
    return (
      icon.name.toLowerCase().includes(normalizedQuery) ||
      icon.searchTerms.some((term: any) => term.toLowerCase().includes(query))
    );
  });

  return (
    <Grid
      style={{
        height: "calc(100vh - 2 * var(--app-shell-padding))",
      }}
      gutter={0}
    >
      <Grid.Col span={2} style={{ height: "100%" }}></Grid.Col>
      <Grid.Col span={5} px="lg" style={{ height: "100%" }}>
        <ScrollArea
          style={{
            height: "calc(100vh - 2 * var(--app-shell-padding))",
          }}
          pr="xl"
        >
          <Group>
            <div>
              <Logo />
            </div>
            <ColorInput
              styles={{
                input: {
                  borderColor: "var(--mantine-color-dark-1)",
                  backgroundColor: "var(--mantine-color-beige-0)",
                  color: "var(--mantine-color-dark-1)",
                },
              }}
              format="hex"
              radius="md"
              defaultValue={primaryColor}
              swatches={["#ff6d33", "#1d8fe0", "#b540f5", "#59db39", "#ffc32b"]}
              onChange={(color) => setPrimaryColor(color)}
              my="xl"
            />
          </Group>

          <Text size="sm" mt="xl" mb="md" fw={300}>
            Toybox Icons is an free-to-use 3D illustration library. Feel free to
            use these images in your project, whether personal or commercial.
          </Text>
          <Text size="sm" mb="xl" fw={300}>
            Created by{" "}
            <a
              href="https://janakitti.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "var(--mantine-color-beige-2)",
                textDecoration: "none",
              }}
            >
              @janakitti
            </a>
          </Text>
          <TextInput
            placeholder="Search icons"
            styles={{
              input: {
                borderColor: "var(--mantine-color-dark-1)",
                backgroundColor: "var(--mantine-color-beige-0)",
                color: "var(--mantine-color-dark-1)",
              },
            }}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Divider my="xl" color="var(--mantine-color-dark-1)" />
          <SimpleGrid spacing="xl" verticalSpacing="xl" cols={4}>
            {filteredIcons.map((item) => (
              <GadgetCard
                key={item.id}
                name={item.name}
                onClick={() => setSelectedGadget(item)}
              >
                <div
                  ref={item.ref}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                ></div>
              </GadgetCard>
            ))}
          </SimpleGrid>
        </ScrollArea>
        <Canvas
          style={{
            position: "fixed",
            left: "0",
            top: "var(--app-shell-padding)",
            height: "calc(100vh - 2 * var(--app-shell-padding))",
            zIndex: 100,
            pointerEvents: "none",
          }}
        >
          {filteredIcons.map((item, index) => (
            <View key={item.id} track={item.ref}>
              <ambientLight intensity={Math.PI / 2} />
              <pointLight position={[1, 1, 3]} intensity={7} />
              {gadgetFactory(item.id, primaryColor)}
              <PerspectiveCamera makeDefault position={[0, 0, 6]} />
            </View>
          ))}
        </Canvas>
      </Grid.Col>
      <Grid.Col
        span={3}
        style={{
          height: "calc(100vh - 2 * var(--app-shell-padding))",
        }}
      >
        {/* <div
          style={{
            borderLeft: "0.05em solid black",
            height: "100%",
          }}
        ></div> */}
        <Tinkerer />
      </Grid.Col>
      <Grid.Col span={2} style={{ height: "100%" }}></Grid.Col>
    </Grid>
  );
}

export default Gallery;
