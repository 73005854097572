import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ControlPanel from "../ControlPanel";
import chroma from "chroma-js";
import { Vector3 } from "three";
import "../CustomStyles.css";

const FILENAME = "tetromino";

interface ITetrominoProps {
  primaryColor: string;
  positionId?: number;
}

interface ITetrominoEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const TetrominoInternals = ({ primaryColor, positionId }: ITetrominoProps) => {
  if (positionId === undefined) {
    positionId = 0;
  }

  const colorScale = chroma
    .scale([chroma(primaryColor).darken(2), chroma(primaryColor).brighten(2)])
    .colors(4);
  const positions = [
    [
      new Vector3(0, 0, 0),
      new Vector3(1, 0, 0),
      new Vector3(-1, 0, 0),
      new Vector3(0, 1, 0),
    ],
    [
      new Vector3(-0.5, 0, 0),
      new Vector3(-1.5, 0, 0),
      new Vector3(0.5, 0, 0),
      new Vector3(1.5, 0, 0),
    ],
    [
      new Vector3(-0.5, -0.5, 0),
      new Vector3(0.5, -0.5, 0),
      new Vector3(0.5, 0.5, 0),
      new Vector3(-0.5, 0.5, 0),
    ],
    [
      new Vector3(0, 0, 0),
      new Vector3(1, 0, 0),
      new Vector3(-1, 0, 0),
      new Vector3(-1, 1, 0),
    ],
    [
      new Vector3(-0.5, 0, 0),
      new Vector3(-0.5, 1, 0),
      new Vector3(0.5, 0, 0),
      new Vector3(0.5, -1, 0),
    ],
  ];
  return (
    <>
      <mesh scale={[1, 1, 1]} position={[0, -0.5, 0]}>
        <mesh position={positions[positionId][0]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={colorScale[0]} />
        </mesh>
        <mesh position={positions[positionId][1]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={colorScale[1]} />
        </mesh>
        <mesh position={positions[positionId][2]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={colorScale[2]} />
        </mesh>
        <mesh position={positions[positionId][3]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={colorScale[3]} />
        </mesh>
      </mesh>
    </>
  );
};

export const Tetromino = ({ primaryColor, positionId }: ITetrominoProps) => {
  return (
    <TetrominoInternals primaryColor={primaryColor} positionId={positionId} />
  );
};

const DownloadableTetromino = forwardRef(
  ({ primaryColor, positionId }: ITetrominoProps, ref) => {
    const gl = useThree((state) => state.gl);

    useImperativeHandle(ref, () => ({
      download() {
        const link = document.createElement("a");
        link.setAttribute("download", `${FILENAME}.png`);
        link.setAttribute(
          "href",
          gl.domElement
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    }));

    return (
      <TetrominoInternals primaryColor={primaryColor} positionId={positionId} />
    );
  }
);

export const TetrominoEditor = ({ primaryColor }: ITetrominoEditorProps) => {
  const childRef = useRef<IDownloadHandle>();
  const [positionId, setPositionId] = useState(0);
  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Tetromino primaryColor={primaryColor} positionId={positionId} />}
      downloadable={
        <DownloadableTetromino
          ref={childRef}
          primaryColor={primaryColor}
          positionId={positionId}
        />
      }
    >
      <Text size="sm">Configuration</Text>
      <Slider
        className="track"
        min={0}
        max={4}
        step={1}
        value={positionId}
        onChange={setPositionId}
      />
    </ControlPanel>
  );
};
