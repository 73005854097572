import { Button, Card, Slider, Stack, Text } from "@mantine/core";
import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ControlPanel from "../ControlPanel";
import { Geometry, Base, Subtraction } from "@react-three/csg";
import chroma from "chroma-js";

const FILENAME = "ring";

interface IRingProps {
  primaryColor: string;
}

interface IRingEditorProps {
  primaryColor: string;
}

interface IDownloadHandle {
  download: () => void;
}

const RingInternals = ({ primaryColor }: IRingProps) => {
  const shiftedColor = chroma(primaryColor).set("hsl.h", "+180").hex();
  return (
    <>
      <mesh scale={[1, 1, 1]}>
        <mesh>
          <Geometry useGroups>
            <Base>
              <torusGeometry />
              <meshStandardMaterial color={primaryColor} />
            </Base>
            <Subtraction position={[1.5, 0, 0]}>
              <boxGeometry args={[3, 3, 3]} />
            </Subtraction>
          </Geometry>
        </mesh>
        <mesh>
          <Geometry useGroups>
            <Base>
              <torusGeometry />
              <meshPhysicalMaterial
                color={shiftedColor}
                thickness={0.5}
                roughness={0}
                transmission={1}
                clearcoat={1}
                envMapIntensity={0.8}
              />
            </Base>
            <Subtraction position={[-1.5, 0, 0]}>
              <boxGeometry args={[3, 3, 3]} />
            </Subtraction>
          </Geometry>
        </mesh>
      </mesh>
    </>
  );
};

export const Ring = ({ primaryColor }: IRingProps) => {
  return <RingInternals primaryColor={primaryColor} />;
};

const DownloadableRing = forwardRef(({ primaryColor }: IRingProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    download() {
      const link = document.createElement("a");
      link.setAttribute("download", `${FILENAME}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  }));

  return <RingInternals primaryColor={primaryColor} />;
});

export const RingEditor = ({ primaryColor }: IRingEditorProps) => {
  const childRef = useRef<IDownloadHandle>();

  return (
    <ControlPanel
      onClick={() => {
        if (childRef && childRef.current) {
          childRef.current.download();
        }
      }}
      scene={<Ring primaryColor={primaryColor} />}
      downloadable={
        <DownloadableRing ref={childRef} primaryColor={primaryColor} />
      }
    ></ControlPanel>
  );
};
